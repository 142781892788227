import '../../styles/about.css';


const PlanetName = ({name}) => {
    
    return (
     
          <h1 className="planet-name">{name}</h1>
  
    )
}

export default PlanetName;