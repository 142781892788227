import './App.css';
import Navigation from './navigation/index';


function App() {

  
  return (
    <div className="App">
        <div className="App">
      <Navigation />
    </div>
    </div>
  );
}

export default App;
